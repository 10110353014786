import { useContext, useMemo } from "react";
import "reactflow/dist/style.css";
import { HeaderSection } from "./TableDetails";
import { LensTypeBadge } from "./CustomNodes";
import { destructColumn, destructTable } from "./utils";
import { Modal, ModalBody } from "reactstrap";
import styles from "./styles.module.scss";
import CodeSQLEditor from "../CodeEditor/SQLCodeEditor";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-close.svg";
import { LineageContext } from "./Lineage";

export function LensCodeModal() {
  const { lensCodeModal, setLensCodeModal } = useContext(LineageContext);
  const viewsCodesFlat = useMemo(() => {
    const arr = Object.values(lensCodeModal?.lensCodes || {})
      .flat()
      .filter(([, type]) => type === "transform")
      .map(([code]) => code);
    const result = [];
    for (const item of arr) {
      if (result.includes(item)) continue;
      result.push(item);
    }
    return result;
  }, [lensCodeModal?.lensCodes]);
  return (
    <Modal
      size="lg"
      isOpen={Boolean(lensCodeModal)}
      toggle={() => setLensCodeModal(null)}
      centered
      unmountOnClose
      scrollable
    >
      <ModalBody>
        <div
          className={styles.close_button}
          onClick={() => setLensCodeModal(null)}
        >
          <CloseIcon />
        </div>
        {Boolean(lensCodeModal) && (
          <div className="d-flex flex-column gap-sm">
            <HeaderSection table={lensCodeModal.table} />
            <div className="d-flex flex-column gap-xs">
              <div className="text-dark-grey fs-xs">Column</div>
              <div className={styles.model_lens_type}>
                {lensCodeModal.column}
              </div>
            </div>
            <div className="d-flex flex-column gap-xs">
              <div className="text-dark-grey fs-xs">Type</div>
              <div className={styles.model_lens_type}>
                {lensCodeModal.lensType && (
                  <LensTypeBadge lensType={lensCodeModal.lensType} />
                )}
                {lensCodeModal.lensType}
              </div>
            </div>
            <div className="d-flex flex-column gap-xs">
              <div className="text-dark-grey fs-xs">Code</div>
              {viewsCodesFlat.map((code) => (
                <div key={code} className="d-flex align-items-center gap-sm">
                  <CodeSQLEditor value={code} height="20px" />
                </div>
              ))}
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
