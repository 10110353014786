import styles from "./styles.module.scss";
import { Button } from "reactstrap";
import { ReactComponent as InsightIcon } from "@/assets/icons/insight.svg";
import { useQuery } from "react-query";
import { Feedback } from "../../Components/Feedback";
import {
  getTableInsightsById,
  postCustomTableInsight,
} from "../../helpers/backend_helper";
import { useBoolean } from "../../helpers/hooks";
import { useState } from "react";
import { Input } from "reactstrap";
import { useTenantUsers } from "../../services/common";
import { ReactComponent as UserIcon } from "@/assets/icons/user.svg";
import { useMutation } from "react-query";
import TimeInfoDisclaimer from "../../Components/TimeInfoDisclaimer";
import classNames from "classnames";

const GET_TABLE_INSIGHTS_BY_ID = "GET-TABLE_INSIGHTS-BY-ID";

const TableInsights = ({ tableId }) => {
  const { value: showCustomInsightsBox, toggle: toggleCustomInsightsBox } =
    useBoolean(false);
  const [reason, setReason] = useState("");
  const {
    data: tableInsights,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [GET_TABLE_INSIGHTS_BY_ID, tableId],
    queryFn: () => {
      return getTableInsightsById(tableId);
    },
    onSuccess: (tableInsights) => {
      if (tableInsights?.custom_tag?.reason) {
        setReason(tableInsights?.custom_tag?.reason);
      }
    },
  });
  const { users } = useTenantUsers();
  const user =
    users?.find((u) => u.id === tableInsights?.custom_tag?.user)?.first_name ||
    "";

  const handleCustomTableTagInputChange = (e) => {
    setReason(e.target.value);
  };

  const { mutate: saveCustomInsight, error } = useMutation(
    postCustomTableInsight,
    {
      onSuccess: (response) => {
        if (response?.ok) {
          refetch().then(() => toggleCustomInsightsBox());
        }
      },
    }
  );

  const handleCustomInsightsSave = () => {
    saveCustomInsight({ id: tableId, data: { reason: reason } });
  };

  return (
    <div className={styles.table_insights_main}>
      <div className={styles.table_insights}>
        <div
          className={classNames(
            styles.heading,
            "d-flex align-items-center gap-lg bg-white p-3 border-radius-top"
          )}
        >
          Insights
          <div className="spacer" />
          {!isLoading && tableInsights?.insights && (
            <TimeInfoDisclaimer
              numOfSecondsActive={3}
              textToDisplay={
                <div className={classNames(styles.info_text, "text-muted m-2")}>
                  Table insights data is for the last 1 month
                </div>
              }
            />
          )}
        </div>
        <div className={styles.insight_container}>
          {tableInsights?.tags?.map((tag) => (
            <div className={styles.insight_card} key={tag.label}>
              <div>
                <div className="d-flex gap-sm mb-2">
                  <InsightIcon />
                  <div className="fs-5">{tag.label}</div>
                </div>
                <div className="text-muted">{tag?.reason}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.custom_insights_analysis}>
          <div className={styles.table_insights_analysis}>
            <div className="d-flex align-items-center gap-lg">
              <div className={styles.title}>Analysis & Notes</div>
              {user && (
                <div className={styles.user}>
                  <UserIcon />
                  <div className={styles.text}>{user}</div>
                </div>
              )}
            </div>
            {!showCustomInsightsBox && (
              <Button
                className={styles.button}
                onClick={toggleCustomInsightsBox}
              >
                <div className={styles.text}>
                  {tableInsights?.custom_tag?.reason ? "Edit" : "Add"}
                </div>
              </Button>
            )}
          </div>
          {!showCustomInsightsBox && tableInsights?.custom_tag?.reason && (
            <div className={styles.edit_data}>
              <div className={styles.text}>
                {tableInsights?.custom_tag?.reason}
              </div>
            </div>
          )}
          {showCustomInsightsBox && (
            <div className={styles.insights}>
              <Input
                className={styles.input}
                type="textarea"
                value={reason}
                onChange={handleCustomTableTagInputChange}
              />
              <div className={styles.buttons}>
                <Button
                  className={styles.save}
                  onClick={handleCustomInsightsSave}
                >
                  <div className={styles.text}>Save</div>
                </Button>
                <Button
                  outline
                  className={styles.cancel}
                  onClick={toggleCustomInsightsBox}
                >
                  <div className={styles.text}>Cancel</div>
                </Button>
              </div>
            </div>
          )}
        </div>

        <Feedback feedback_id={tableId} feedback_type="table_insights" />
      </div>
    </div>
  );
};

export { TableInsights };
