import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { CodeSQLEditor } from "../../../Components/CodeEditor";
import styles from "../styles.module.scss";
import classNames from "classnames";
import { scroll_style } from "../../../Components/ScrollContainer";
import { ArrowLeftIcon, ArrowRightIcon } from "@assets/icons";
const getNavClass = (b) => classNames("icon-sm", b ? "text-black cursor-pointer" : "text-muted");
export const InsightExplanation = ({ selectedInsight, spans, spanIndex, onNext, onPrev, showSpan, }) => {
    if (!(showSpan || selectedInsight.description || selectedInsight.reason)) {
        return null;
    }
    return (_jsxs("div", { className: "br-2 bg-grey", children: [_jsxs("div", { className: "d-flex align-items-center p-2", children: [_jsxs("div", { className: "fs-5 fw-semibold", children: ["Details: \"", selectedInsight.tag_rk, "\""] }), _jsx("div", { className: "spacer" }), spans.length > 0 && (_jsxs("div", { className: styles.highlight_nav, children: [spanIndex + 1, " ", _jsxs("div", { className: "text-muted", children: [" - ", spans.length] }), _jsx(ArrowLeftIcon, { className: getNavClass(spanIndex > 0), onClick: onPrev }), _jsx(ArrowRightIcon, { className: getNavClass(spanIndex < spans.length - 1), onClick: onNext })] }))] }), _jsx("div", { className: "divider" }), showSpan ? (_jsxs("div", { className: styles.insight_selected_details, children: [_jsxs("div", { className: "bg-blue-dark br-2", children: [_jsx("div", { className: "fs-4 fw-500 p-3 text-white", children: "Query" }), _jsx(CodeSQLEditor, { value: spans[spanIndex].query_span, height: "14vh" })] }), _jsxs("div", { className: "bg-white p-3 br-2", children: [_jsx("div", { className: "fs-4 fw-500 mb-2", children: "Problem" }), _jsx("div", { className: classNames("word-break text-muted", styles.insight_description, scroll_style), children: spans[spanIndex].description })] })] })) : (_jsxs("div", { className: "bg-white p-3 br-2 m-3", children: [_jsx("div", { className: "fs-4 fw-500 mb-2", children: "Problem" }), _jsx("div", { className: classNames("word-break text-muted", styles.insight_description, scroll_style), children: selectedInsight.description || selectedInsight.reason })] }))] }));
};
