import { useContext, useState } from "react";
import { LineageContext } from "./Lineage";
import { DebouncedInput } from "../Form";
import styles from "./styles.module.scss";
import { getIconByDatastoreType } from "../../pages/Datasets/DatastoreImage";
import classNames from "classnames";
import { useReactFlow } from "reactflow";
import { createTableEdge, createTableNode, destructTable } from "./utils";
import { layoutElementsOnCanvas, processColumnLineage } from "./graph";

const MoreTables = () => {
  const {
    moreTables,
    selectedColumn,
    setCollectColumns,
    postConnectedColumns,
  } = useContext(LineageContext);
  const { prevTable, tables, right, level } = moreTables;
  const flow = useReactFlow();

  // hack to force re-render the component
  const [_, _rerender] = useState(0);
  const rerender = () => _rerender((x) => x + 1);

  const onItemClick = async (_table) => {
    const { table, count } = _table;
    let nodes = flow.getNodes();
    let edges = flow.getEdges();
    const node = nodes.find((n) => n.id === table);
    if (!node) {
      nodes.push(
        createTableNode(
          {
            table,
            upstreamCount: right ? count : 0,
            downstreamCount: !right ? count : 0,
          },
          level,
          prevTable
        )
      );
      const { level: prevTableLevel } = nodes.find(
        (n) => n.id === prevTable
      ).data;
      edges.push(
        createTableEdge(prevTableLevel, level, prevTable, table, right)
      );
    } else {
      nodes = nodes.filter((n) => n.id !== table);
      const _edgeId = right ? `${prevTable}-${table}` : `${table}-${prevTable}`;
      edges = edges.filter((e) => e.id !== _edgeId);
    }
    if (selectedColumn) {
      // TODO: cache the result instead of making api call
      const {
        nodes: _nodes,
        edges: _edges,
        collect_columns,
      } = await processColumnLineage(
        nodes,
        edges,
        selectedColumn,
        table,
        postConnectedColumns
      );
      setCollectColumns(collect_columns);
      nodes = _nodes;
      edges = _edges;
    }

    layoutElementsOnCanvas(nodes, edges);
    flow.setNodes(nodes);
    flow.setEdges(edges);
    rerender();
  };

  const [filteredTables, setFilteredTables] = useState(tables);
  return (
    <div className="p-2 h-100 d-flex flex-column">
      <div className={styles.header}>
        <div className="mb-2 d-flex gap-sm align-items-center">
          <div>Datastore name</div>
        </div>
      </div>
      <div className="mb-3" />
      <DebouncedInput
        size="sm"
        placeholder="Search by table name"
        onChange={(v) => {
          const _search = v.toLowerCase();
          setFilteredTables(
            tables.filter((t) => t.table.toLowerCase().includes(_search))
          );
        }}
      />
      <div className="mb-3" />
      <div className="h-100 overflow-y">
        <div className="d-flex flex-column gap-sm">
          {filteredTables.map((t) => {
            const [datastore_type, schema, tableName] = destructTable(t.table);
            const _node = flow.getNode(t.table);
            const isNodeOnOtherLevel = _node && _node.data.level !== level;
            return (
              <div
                key={t.table}
                className={classNames(styles.table_card, {
                  [styles.selected]: _node,
                  [styles.disabled]: isNodeOnOtherLevel,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isNodeOnOtherLevel) return;
                  onItemClick(t);
                }}
              >
                <div className="d-flex align-items-center gap-sm">
                  {getIconByDatastoreType(datastore_type)}
                  <div className="text-overflow">{tableName}</div>
                </div>
                <div className="text-primary">{schema}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { MoreTables };
