import { useQuery } from "react-query";
import {
  getSummaryGraph,
  getSummaryInsights,
} from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";
import { Card, CardBody } from "reactstrap";
import { GreenTile, HeaderCard, InsightCard, OrangeTile } from "./Cost";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const GOVERNANCE_GRAPH = "GOVERNANCE-GRAPH";
const GOVERNANCE_HEADER = "GOVERNANCE-HEADER";
const GOVERNANCE_INSIGHT = "GOVERNANCE-INSIGHT";

const ChartTile = () => {
  const { data, isLoading } = useQuery({
    queryKey: [GOVERNANCE_GRAPH],
    queryFn: () => getSummaryGraph({ period: 7, insight_type: "governance" }),
  });
  if (isLoading) return <RelativeComponentLoader />;
  const { graph_data } = data;
  return (
    <Card className="mb-0">
      <CardBody>
        <div>
          <div className="text-black fs-5 mb-2">Cost over time</div>
        </div>
        <BarChart width={600} height={300} data={graph_data}>
          <CartesianGrid vertical={false} />
          <Legend verticalAlign="bottom" />
          <XAxis
            dataKey="date"
            angle={-90}
            label={{
              value: "Date",
              position: "insideBottomRight",
              fill: "black",
            }}
            interval={0}
          />
          <YAxis
            label={{
              value: "Cost",
              position: "insideTopLeft",
              fill: "black",
              offset: 0,
            }}
            padding={{ top: 20 }}
          />
          <Tooltip />
          <Bar barSize={8} dataKey="compute" fill="#5B9FFF" stackId="single" />
          <Bar barSize={8} dataKey="storage" fill="#FFCE73" stackId="single" />
          <Bar
            barSize={8}
            dataKey="serverless"
            fill="#9382D9"
            stackId="single"
          />
        </BarChart>
      </CardBody>
    </Card>
  );
};

const Governance = () => {
  const { data: header, isLoading: isHeaderLoading } = useQuery({
    queryKey: [GOVERNANCE_HEADER],
    queryFn: () =>
      getSummaryInsights({
        period: 30,
        insight_type: "governance",
        summary_type: "header",
      }),
  });
  const { data: insight, isLoading: isInsightLoading } = useQuery({
    queryKey: [GOVERNANCE_INSIGHT],
    queryFn: () =>
      getSummaryInsights({
        period: 30,
        insight_type: "governance",
        summary_type: "insight",
      }),
  });
  if (isHeaderLoading || isInsightLoading) return <RelativeComponentLoader />;
  return (
    <div className="d-flex flex-column gap-md">
      <HeaderCard headers={header.summary_data} />
      <div className="d-flex gap-md">
        <div className="flex-grow">
          <ChartTile />
        </div>
        <div className="d-flex flex-column gap-md">
          <GreenTile title="Total Annual Savings" value="$168000" />
          <OrangeTile title="Average Annual Spend" value="$84000" />
          <div className="flex-grow"></div>
        </div>
      </div>
      <InsightCard insights={insight.summary_data} />
    </div>
  );
};

export { Governance };
