import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import profileBg from "../../assets/images/profile-bg.jpg";
import { UserProfileImage } from "../../Components/Profile";
import { RiCameraFill } from "react-icons/ri";
import { getUser } from "../../services/auth";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const user = getUser();

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mx-n4 mt-n4">
          <div className="profile-wid-bg profile-setting-img">
            <img src={profileBg} className="profile-wid-img" alt="" />
          </div>
        </div>
        <Row>
          <Col xxl={3}>
            <Card className="mt-n5">
              <CardBody className="p-4">
                <div className="text-center">
                  <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                    <UserProfileImage size="xl" />
                    <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                      <Input
                        id="profile-img-file-input"
                        type="file"
                        className="profile-img-file-input"
                      />
                      <Label
                        htmlFor="profile-img-file-input"
                        className="profile-photo-edit avatar-xs m-0"
                      >
                        <span className="avatar-title rounded-circle bg-light text-body">
                          <RiCameraFill />
                        </span>
                      </Label>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xxl={9}>
            <Card className="mt-xxl-n5">
              <CardHeader>
                <Nav
                  className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        tabChange("1");
                      }}
                    >
                      <i className="fas fa-home"></i>
                      Personal Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        tabChange("2");
                      }}
                      type="button"
                    >
                      <i className="far fa-user"></i>
                      Change Password
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody className="p-4">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Form>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="firstnameInput"
                              className="form-label"
                            >
                              First Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="firstnameInput"
                              placeholder="Enter your firstname"
                              defaultValue={user.first_name}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="lastnameInput"
                              className="form-label"
                            >
                              Last Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="lastnameInput"
                              placeholder="Enter your lastname"
                              defaultValue={user.last_name}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="emailInput" className="form-label">
                              Email Address
                            </Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="emailInput"
                              placeholder="Enter your email"
                              defaultValue={user.email}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="phonenumberInput"
                              className="form-label"
                            >
                              API Key
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="apiKey"
                              readOnly
                              defaultValue={user.auth_token}
                            />
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-primary">
                              Updates
                            </button>
                            <button
                              type="button"
                              className="btn btn-soft-success"
                            >
                              Cancel
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>

                  <TabPane tabId="2">
                    <Form>
                      <Row className="g-2">
                        <Col lg={4}>
                          <div>
                            <Label
                              htmlFor="oldpasswordInput"
                              className="form-label"
                            >
                              Old Password
                            </Label>
                            <Input
                              type="password"
                              className="form-control"
                              id="oldpasswordInput"
                              placeholder="Enter current password"
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div>
                            <Label
                              htmlFor="newpasswordInput"
                              className="form-label"
                            >
                              New Password
                            </Label>
                            <Input
                              type="password"
                              className="form-control"
                              id="newpasswordInput"
                              placeholder="Enter new password"
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div>
                            <Label
                              htmlFor="confirmpasswordInput"
                              className="form-label"
                            >
                              Confirm Password
                            </Label>
                            <Input
                              type="password"
                              className="form-control"
                              id="confirmpasswordInput"
                              placeholder="Confirm password"
                            />
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="mb-3">
                            <Link
                              to="#"
                              className="link-primary text-decoration-underline"
                            >
                              Forgot Password ?
                            </Link>
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="text-end">
                            <button type="button" className="btn btn-success">
                              Change Password
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
