import { LENS_TYPE_COLOR } from "./utils";
import styles from "./styles.module.scss";
import { Button, Popover, PopoverBody } from "reactstrap";
import { useEffect, useState } from "react";
import { ReactComponent as DownIcon } from "../../assets/icons/chevron_down.svg";
import { ReactComponent as UpIcon } from "../../assets/icons/chevron_up.svg";
import { isFreeStack } from "../../helpers/tenant";
import { ReactComponent as CodeIcon } from "../../assets/icons/code3.svg";

const LineageLegend = () => {
  const [showLegend, setShowLegend] = useState(false);
  const toggleLegend = () => setShowLegend(!showLegend);

  useEffect(() => {
    setTimeout(() => setShowLegend(true), 500)
    const timeoutId = setTimeout(() => setShowLegend(false), 3500);
    return () => clearTimeout(timeoutId);
  }, []);

  if (!isFreeStack()) {
    return null;
  }

  return (
    <>
      <Button
        id="lineageLegend"
        className={styles.lineage_legend}
        type="button"
        onClick={toggleLegend}
      >
        Legend
        {showLegend ? <DownIcon /> : <UpIcon />}
      </Button>
      <Popover
        flip
        target="lineageLegend"
        isOpen={showLegend}
        className={styles.column_legend}
        placement="top"
      >
        <PopoverBody>
          {Object.entries(LENS_TYPE_COLOR).map(([k, v]) => (
            <div key={k} className="mb-1 d-flex align-items-center gap-sm">
              <div className={styles.dot} style={{ backgroundColor: v }}>
                {k[0]}
              </div>
              {k}
            </div>
          ))}
          <div className="mb-1 d-flex align-items-center gap-sm">
            <div className={styles.column_code_icon}>
              <CodeIcon />
            </div>
            <div>Code</div>
          </div>
          <div className="d-flex gap-sm mb-1 align-items-center">
            <div className={styles.edge_select}>
              <div />
            </div>
            <div>Select</div>
          </div>
          <div className="d-flex gap-sm mb-1 align-items-center">
            <div className={styles.edge_non_select}>
              <div />
            </div>
            <div>Non select</div>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default LineageLegend;
