import "reactflow/dist/style.css";
import {
  COLUMN_PREFIX,
  LEVEL_SEPARATION,
  MAX_EXPAND_TABLE,
  C_OFFSET_X,
  C_OFFSET_Y,
  T_NODE_H,
  T_NODE_W,
  applyEdgeStyling,
  createTableNode,
  defaultEdgeStyle,
  destructColumn,
  isColumn,
  isNotColumn,
  getColY,
  isSeeMore,
  F_OFFSET_Y,
  F_OFFSET_X,
  T_NODE_Y_SEPARATION,
  createTableEdge,
  getSeeMoreId,
  createColumnEdge,
  getColumnEdgeId,
  LEVEL_SEPARATION_VERTICAL,
  T_NODE_Y_SEPARATION_VERTICAL,
  getColumnId,
  createOpNode,
} from "./utils";

const calculateNewLevel = (right, i) => (right ? i + 1 : i - 1);

export const createNewNodesEdges = (
  nodes,
  edges,
  tables,
  t,
  right,
  level,
  max_expand_table = MAX_EXPAND_TABLE,
  isVertical = false,
  details = null
) => {
  const newLevel = calculateNewLevel(right, level);

  const addUniqueEdge = (to) => {
    const toLevel = nodes.find((n) => n.id === to)?.data?.level;
    const _edge = createTableEdge(level, toLevel, t, to, right, isVertical);
    const existingEdge = edges.find((e) => e.id === _edge.id);
    if (!existingEdge) edges.push(_edge);
  };

  let tableAdded = 0;
  for (const _t of tables) {
    if (tableAdded >= max_expand_table) {
      const nodeId = getSeeMoreId(t, right);
      nodes.push({
        id: nodeId,
        data: { tables, prevTable: t, right, level: newLevel },
        position: { x: 100, y: 100 },
        type: "seeMore",
        width: T_NODE_W,
        height: 100,
      });
      addUniqueEdge(nodeId);
      break;
    }
    const existingNode = nodes.find((_n) => _n.id === _t.table);
    if (!existingNode) {
      tableAdded++;
      if (!details) {
        nodes.push(
          createTableNode(
            {
              table: _t.table,
              upstreamCount: right ? _t.count : 0,
              downstreamCount: !right ? _t.count : 0,
            },
            newLevel,
            t
          )
        );
      } else {
        const opType = details[_t.table].type;
        if (!opType || ["cte", "table", "final"].includes(opType)) {
          nodes.push(
            createTableNode(
              {
                table: _t.table,
                upstreamCount: right ? _t.count : 0,
                downstreamCount: !right ? _t.count : 0,
              },
              newLevel,
              t
            )
          );
        } else {
          nodes.push(createOpNode(_t.table, newLevel, t, details[_t.table]));
        }
      }
    }
    addUniqueEdge(_t.table);
  }
};

export const resetTableHighlights = (nodes, edges) => {
  nodes.forEach((n) => (n.style = { opacity: 1 }));
  edges.forEach((e) => applyEdgeStyling(e, false));
  return [nodes, edges];
};

export const highlightTableConnections = (nodes, edges, table) => {
  const highlightNode = {};
  const highlightEdge = {};
  const bfsTraversal = (src, dst) => {
    const queue = [table];
    const visited = {};
    while (queue.length > 0) {
      const curr = queue.shift();
      visited[curr] = true;
      highlightNode[curr] = true;
      edges.forEach((e) => {
        if (e[src] === curr) {
          highlightEdge[e.id] = true;
          if (!visited[e[dst]]) queue.push(e[dst]);
        }
      });
    }
  };
  bfsTraversal("source", "target");
  bfsTraversal("target", "source");

  // apply styling
  const newEdges = [...edges];
  newEdges.forEach((_e) => applyEdgeStyling(_e, highlightEdge[_e.id]));

  const newNodes = [...nodes];
  newNodes.forEach(
    (_n) => (_n.style = { opacity: highlightNode[_n.id] ? 1 : 0.5 })
  );

  return [newNodes, newEdges];
};

// TODO: fix member_profile-> expand left, expand right, collapse left, collapse right
export const removeRelatedNodesEdges = (
  prevNodes,
  prevEdges,
  table,
  right,
  level
) => {
  const nodesToRemove = {};
  const edgesToRemove = {};
  const src = right ? "source" : "target";
  const dst = !right ? "source" : "target";

  const nodesIdMap = {};
  for (const n of prevNodes) {
    if (isColumn(n)) continue;
    nodesIdMap[n.id] = n;
  }

  // TODO: check visited == nodesToRemove
  const queue = [table];
  const visited = {};
  while (queue.length > 0) {
    const curr = queue.shift();
    visited[curr] = true;
    prevEdges.forEach((e) => {
      if (e[src] !== curr) return;
      const _t = e[dst];
      if (visited[_t]) return;
      const _level = nodesIdMap[_t].data.level;
      if ((right && _level > level) || (!right && _level < level)) {
        queue.push(_t);
        nodesToRemove[_t] = true;
      }
    });
  }

  const columnNodesToRemove = {};
  const columnEdgesToRemove = {};

  prevNodes.forEach((n) => {
    if (!nodesToRemove[n.parentNode]) return;
    columnNodesToRemove[n.id] = true;
  });

  prevEdges.forEach((e) => {
    if (isNotColumn(e)) {
      edgesToRemove[e.id] =
        nodesToRemove[e.source] || nodesToRemove[e.target] || e[src] === table;
    } else {
      columnEdgesToRemove[e.id] =
        columnNodesToRemove[e.source] || columnNodesToRemove[e.target];
    }
  });

  const remove = (dict) => (x) => !dict[x.id];

  const newNodes = prevNodes
    .filter(remove(columnNodesToRemove))
    .filter(remove(nodesToRemove));
  const newEdges = prevEdges
    .filter(remove(columnEdgesToRemove))
    .filter(remove(edgesToRemove));

  const _node = newNodes.find((_n) => _n.id === table);
  if (_node) _node.data.processed[right ? 1 : 0] = false;

  return [newNodes, newEdges];
};

export const layoutElementsOnCanvas = (nodes, edges, isVertical = false) => {
  let minLevel = Infinity;
  let maxLevel = -Infinity;
  const tableWiseColumnCount = {};

  for (const n of nodes) {
    if (isColumn(n) && n.parentNode) {
      // assign position to columns earlier as they are relative to parent
      if (!(n.parentNode in tableWiseColumnCount)) {
        tableWiseColumnCount[n.parentNode] = 0;
      }

      n.position = {
        x: C_OFFSET_X,
        y: C_OFFSET_Y + getColY(tableWiseColumnCount[n.parentNode]),
      };
      tableWiseColumnCount[n.parentNode]++;
    } else {
      // calculate bounds along x axis
      const { level } = n.data;
      minLevel = Math.min(minLevel, level);
      maxLevel = Math.max(maxLevel, level);
    }
  }

  const tableWiseLevelColumnCount = {};
  const levelWiseTables = {};
  const tableWiseLevelPos = {};
  const visited = {};

  // create neighbours list for convenience
  const adjacencyListRight = {};
  const adjacencyListLeft = {};
  for (const e of edges) {
    if (isColumn(e)) continue;
    if (
      isSeeMore(nodes.find((x) => x.id === e.source)) ||
      isSeeMore(nodes.find((x) => x.id === e.target))
    )
      continue;
    adjacencyListRight[e.source] = adjacencyListRight[e.source] || [];
    adjacencyListRight[e.source].push(e.target);
    adjacencyListLeft[e.target] = adjacencyListLeft[e.target] || [];
    adjacencyListLeft[e.target].push(e.source);
  }

  // calculate metadata such as tables and columns per level
  // to get tables position along y axis
  const processNode = (n) => {
    const { level } = n.data;
    levelWiseTables[level] = levelWiseTables[level] || [];
    if (!levelWiseTables[level].includes(n.id)) {
      tableWiseLevelPos[n.id] = levelWiseTables[level].length;
      tableWiseLevelColumnCount[n.id] = 0;
      for (const curr of levelWiseTables[level])
        tableWiseLevelColumnCount[n.id] += tableWiseColumnCount[curr] || 0;
      levelWiseTables[level].push(n.id);
    }
  };

  const dfs = (n, adjacencyList) => {
    if (visited[n]) return;
    visited[n] = true;
    processNode(nodes.find((item) => item.id === n));
    for (const m of adjacencyList[n] || []) dfs(m, adjacencyList);
  };

  for (const n of nodes) {
    if (isColumn(n)) continue;
    if (isSeeMore(n)) continue;
    if (visited[n.id]) continue;
    dfs(n.id, adjacencyListRight);
    visited[n.id] = false;
    dfs(n.id, adjacencyListLeft);
  }

  // will place see more node at the end of level
  for (const n of nodes) {
    if (isColumn(n)) continue;
    if (!isSeeMore(n)) continue;
    processNode(n);
  }

  // assign position to table and see more nodes
  const getY = (n) => {
    const _index = tableWiseLevelPos[n.id] || 0;
    const _columnCount = tableWiseLevelColumnCount[n.id] || 0;
    return (
      F_OFFSET_Y +
      _index * (T_NODE_H + T_NODE_Y_SEPARATION) +
      getColY(_columnCount, _index)
    );
  };

  const getX = (level) => {
    const basisLevel = level - minLevel;
    return basisLevel * (T_NODE_W + LEVEL_SEPARATION) + F_OFFSET_X;
  };

  const getYVertical = (level) => {
    const basisLevel = level - minLevel;
    return basisLevel * (T_NODE_H + LEVEL_SEPARATION_VERTICAL) + F_OFFSET_Y;
  };

  const getXVertical = (n) => {
    const _index = tableWiseLevelPos[n.id] || 0;
    const _columnCount = tableWiseLevelColumnCount[n.id] || 0;
    return (
      F_OFFSET_Y +
      _index * (T_NODE_H + T_NODE_Y_SEPARATION_VERTICAL) +
      getColY(_columnCount, _index)
    );
  };

  for (const n of nodes) {
    if (isColumn(n)) continue;
    const { level } = n.data;
    n.position = isVertical
      ? { x: getXVertical(n), y: getYVertical(level) }
      : { x: getX(level), y: getY(n) };
  }
};

export const processColumnLineage = async (
  _nodes,
  _edges,
  column_rk,
  selected_table,
  postConnectedColumns
) => {
  let nodes = _nodes.filter(isNotColumn);
  let edges = _edges.filter(isNotColumn);
  [nodes, edges] = resetTableHighlights(nodes, edges);

  const levelMap = {};
  nodes.forEach((n) => (levelMap[n.id] = n.data.level));

  const tableNodes = {};
  _nodes
    .filter((_n) => _n.type === "table")
    .forEach((_n) => (tableNodes[_n.id] = true));
  const seeMoreIdTableReverseMap = {};
  const edgesPayload = [];
  for (const e of _edges) {
    if (e.id.startsWith(COLUMN_PREFIX)) continue;
    const sourceTableExist = tableNodes[e.source];
    const targetTableExist = tableNodes[e.target];
    if (sourceTableExist && targetTableExist) {
      edgesPayload.push({ src: e.source, dst: e.target });
    } else if (sourceTableExist) {
      const _n = _nodes.find((_n) => _n.id === e.target);
      _n.data.tables.forEach((_t) => {
        edgesPayload.push({ src: e.source, dst: _t.table });
        seeMoreIdTableReverseMap[_t.table] = e.target;
      });
    } else if (targetTableExist) {
      const _n = _nodes.find((_n) => _n.id === e.source);
      _n.data.tables.forEach((_t) => {
        edgesPayload.push({ src: _t.table, dst: e.target });
        seeMoreIdTableReverseMap[_t.table] = e.source;
      });
    } else {
      // TODO: check is nothing to do in this case
    }
  }

  const { collect_columns, highlight_edges } = await postConnectedColumns({
    column_fqn: column_rk,
    edges: edgesPayload,
  });

  for (const t in collect_columns) {
    if (!tableNodes[t]) continue;
    collect_columns[t].sort();
    for (const c of collect_columns[t]) {
      const id = getColumnId(t, c);
      const currColumnEdges = highlight_edges.filter(
        (e) => e["target"] === `${t}/${c}`
      );
      const lensCodes = {};
      currColumnEdges.forEach((e) => {
        const _lens_code = e.extra?.lens_code;
        if (_lens_code) lensCodes[e["source"]] = _lens_code;
      });
      nodes.push({
        id,
        data: {
          column: c,
          table: t,
          lensType:
            currColumnEdges.length > 0
              ? currColumnEdges[0].extra?.lens_type
              : "",
          lensCodes,
        },
        parentNode: t,
        extent: "parent",
        draggable: false,
        type: "column",
      });
    }
  }

  edges.forEach((_e) => (_e.style = defaultEdgeStyle));
  const addToEdges = (id1, id2, source, target) => {
    const id = getColumnEdgeId(source, target);
    if (edges.find((e) => e.id === id)) return;
    edges.push(createColumnEdge(source, target, levelMap[id1], levelMap[id2]));
  };
  const seeMoreLineage = [];

  for (const e of highlight_edges) {
    const [t0] = destructColumn(e["source"]);
    const [t1] = destructColumn(e["target"]);
    const sourceTableExist = tableNodes[t0];
    const targetTableExist = tableNodes[t1];
    const source = COLUMN_PREFIX + e["source"];
    const target = COLUMN_PREFIX + e["target"];
    if (sourceTableExist && targetTableExist) {
      addToEdges(t0, t1, source, target);
    } else if (sourceTableExist) {
      const seeMoreId = seeMoreIdTableReverseMap[t1];
      addToEdges(t0, seeMoreId, source, seeMoreId);
      seeMoreLineage.push(e);
    } else if (targetTableExist) {
      const seeMoreId = seeMoreIdTableReverseMap[t0];
      addToEdges(seeMoreId, t1, seeMoreId, target);
      seeMoreLineage.push(e);
    } else {
      seeMoreLineage.push(e);
      // TODO: check is nothing to do in this case
    }
  }

  layoutElementsOnCanvas(nodes, edges);

  return { nodes, edges, collect_columns };
};

export const staticProcessColumnLineage = async (
  _nodes,
  _edges,
  column_rk,
  selected_table,
  postConnectedColumns
) => {
  let nodes = _nodes.filter(isNotColumn);
  let edges = _edges.filter(isNotColumn);
  [nodes, edges] = resetTableHighlights(nodes, edges);

  const levelMap = {};
  nodes.forEach((n) => (levelMap[n.id] = n.data.level));

  const tableNodes = {};
  _nodes
    .filter((_n) => _n.type === "table")
    .forEach((_n) => (tableNodes[_n.id] = true));
  const seeMoreIdTableReverseMap = {};
  const edgesPayload = [];
  for (const e of _edges) {
    if (e.id.startsWith(COLUMN_PREFIX)) continue;
    const sourceTableExist = tableNodes[e.source];
    const targetTableExist = tableNodes[e.target];
    if (sourceTableExist && targetTableExist) {
      edgesPayload.push({ src: e.source, dst: e.target });
    } else if (sourceTableExist) {
      const _n = _nodes.find((_n) => _n.id === e.target);
      _n.data.tables.forEach((_t) => {
        edgesPayload.push({ src: e.source, dst: _t.table });
        seeMoreIdTableReverseMap[_t.table] = e.target;
      });
    } else if (targetTableExist) {
      const _n = _nodes.find((_n) => _n.id === e.source);
      _n.data.tables.forEach((_t) => {
        edgesPayload.push({ src: _t.table, dst: e.target });
        seeMoreIdTableReverseMap[_t.table] = e.source;
      });
    } else {
      // TODO: check is nothing to do in this case
    }
  }

  const { collect_columns, highlight_edges } = await postConnectedColumns({
    column_fqn: column_rk,
    edges: edgesPayload,
  });

  for (const t in collect_columns) {
    if (!tableNodes[t]) continue;
    collect_columns[t].sort();
    for (const c of collect_columns[t]) {
      nodes.push({
        id: COLUMN_PREFIX + `${t}/${c}`,
        data: { column: c, table: t },
        parentNode: t,
        extent: "parent",
        draggable: false,
        type: "column",
      });
    }
  }

  edges.forEach((_e) => (_e.style = defaultEdgeStyle));
  const addToEdges = (id1, id2, source, target) => {
    const id = getColumnEdgeId(source, target);
    if (edges.find((e) => e.id === id)) return;
    edges.push(createColumnEdge(source, target, levelMap[id1], levelMap[id2]));
  };
  const seeMoreLineage = [];

  for (const e of highlight_edges) {
    const [t0] = destructColumn(e[0]);
    const [t1] = destructColumn(e[1]);
    const sourceTableExist = tableNodes[t0];
    const targetTableExist = tableNodes[t1];
    const source = COLUMN_PREFIX + e[0];
    const target = COLUMN_PREFIX + e[1];
    if (sourceTableExist && targetTableExist) {
      addToEdges(t0, t1, source, target);
    } else if (sourceTableExist) {
      const seeMoreId = seeMoreIdTableReverseMap[t1];
      addToEdges(t0, seeMoreId, source, seeMoreId);
      seeMoreLineage.push(e);
    } else if (targetTableExist) {
      const seeMoreId = seeMoreIdTableReverseMap[t0];
      addToEdges(seeMoreId, t1, seeMoreId, target);
      seeMoreLineage.push(e);
    } else {
      seeMoreLineage.push(e);
      // TODO: check is nothing to do in this case
    }
  }

  layoutElementsOnCanvas(nodes, edges);

  return { nodes, edges, collect_columns };
};
